import { ReactNode } from "react"
import FivaNavLayout from "./FivaNavLayout/FivaNavLayout"
import FivaFooter from "../components/_Footer/FivaFooter/FivaFooter"

export default function FivaLayout({
  children,
  blue,
}: {
  children: ReactNode
  blue?: boolean
}) {
  return (
    <>
      <FivaNavLayout />
      <main>{children}</main>
      <FivaFooter blue={blue} />
    </>
  )
}
