import FivaNavbar from "../../components/_Navbars/FivaNavbar/FivaNavbar"
import FivaNavbarPhone from "../../components/_Navbars/FivaNavbarPhone/FivaNavbarPhone"
import styles from "./FivaNavLayout.module.css"

const FivaNavLayout = () => (
  <>
    <div className={styles.big}>
      <FivaNavbar />
    </div>
    <div className={styles.small}>
      <FivaNavbarPhone />
    </div>
  </>
)
export default FivaNavLayout
