import { Button } from "antd"
import styles from "./CompareButton.module.css"

export default function CompareButton({
  lightColor = false,
}: {
  lightColor?: boolean
}) {
  return (
    <Button
      type="primary"
      size="large"
      className={lightColor ? styles.lightButtonClass : styles.buttonClass}
    >
      Comparar
      <span style={{ marginLeft: "10px" }}>
        {lightColor ? (
          <img src="/icons/UpVectorPurple.svg" alt="Up" />
        ) : (
          <img src="/icons/UpVector.svg" alt="Up" />
        )}
      </span>
    </Button>
  )
}
