import styles from "./FivaNavbarPhone.module.css"
import Link from "next/link"
import Image from "next/image"
import { useContext, useEffect, useRef, useState } from "react"
import { CollapseContext } from "../../../context/collapse.context"
import CompareButton from "../../_SharedComponents/CompareButton/CompareButton"
import { Pages } from "../../../constants/_pages"
import { ChevronDown } from "lucide-react"

const FivaNavbarPhone = () => {
  const { collapse, setCollapse } = useContext<any>(CollapseContext)
  const contentRef = useRef<any>(null)
  const [height, setHeight] = useState(0)
  const [subMenu, setSubMenu] = useState<string | null>(null)

  useEffect(() => {
    setCollapse(false)
  }, [])

  useEffect(() => {
    if (collapse) {
      setHeight(contentRef.current.scrollHeight)
    } else {
      setHeight(0)
    }
  }, [collapse])

  useEffect(() => {
    if (subMenu) {
      setHeight(contentRef.current.scrollHeight)
    }
  }, [subMenu])

  const toggleSubMenu = (menu: string) => {
    if (subMenu === menu) {
      setSubMenu(null)
    } else {
      setSubMenu(menu)
    }
  }

  const addCollapse = collapse ? { minHeight: "56px" } : {}
  const collapseStyle = {
    height: `${height}px`,
  }
  const LOGO = "/icons/FivaPhone.svg"

  return (
    <div className={styles.container} style={{ ...addCollapse }}>
      <div className={styles.flexContainer}>
        <div className={styles.logo}>
          <Link href="/">
            <Image src={LOGO} alt="Fiva Logo" width={95} height={30} />
          </Link>
        </div>
        <div
          className={styles.menuIcon}
          onClick={() => {
            collapse ? setCollapse(false) : setCollapse(true)
          }}
        >
          <Image src="/icons/menu.svg" alt="Menu Icon" height={40} width={40} />
        </div>
      </div>
      {collapse && (
        <div
          ref={contentRef}
          className={styles.collapseContainer}
          style={collapseStyle}
        >
          <div className={styles.collapseContent}>
            <br />
            <Link href="/">
              <p className={styles.underline}>Ahorra con Fiva</p>
            </Link>
            <div>
              <p
                className={styles.underline}
                onClick={() => toggleSubMenu("luz")}
              >
                Luz
                <ChevronDown strokeWidth="1" size={15} />
              </p>
              {subMenu === "luz" && (
                <div className={styles.subMenu}>
                  <Link href="/precio-luz-hoy">
                    <p className={styles.underline}>Precio de la luz hoy</p>
                  </Link>
                  <Link href={Pages.energy}>
                    <p className={styles.underline}>Asesoramiento Energético</p>
                  </Link>
                </div>
              )}
            </div>
            <Link href="/solar">
              <p className={styles.underline}>Autoconsumo</p>
            </Link>
            <Link href="/blog">
              <p className={styles.underline}>Blog</p>
            </Link>
            <Link href="/contacto">
              <p className={styles.underline}>Contacto</p>
            </Link>
            <Link href="/colaboradores">
              <p className={styles.underline}>Colaboradores</p>
            </Link>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Link href="/sube-tu-factura" passHref>
                <a style={{ textDecoration: "none" }}>
                  <CompareButton />
                </a>
              </Link>
            </div>
            <br />
          </div>
        </div>
      )}
    </div>
  )
}

export default FivaNavbarPhone
